import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import Collection from '../images/new_collection.png'
import Collection2 from '../images/new_collection2.png'
import Collection3 from '../images/new_collection3.png'
import catDrappery from '../images/cat_drappery.png'
import catDrappery2 from '../images/cat_drappery.png'
import Wrapper from "./Wrapper";
import { NavLink } from "react-router-dom";
import { Button, Input } from "../Library/Module";
import ContactForm from "../components/ContactForm";

interface HomeProps {
    link: string;
}

type formDataProps = {
    email_id: string;
    full_name: string;
    business_name?: string;
    phone_number?: string;
    commnet?: string;
};


const ContactUs = ({ link }: HomeProps): JSX.Element => {
    const { orgUser } = useContext(ThemeContext)
    const [disabled, setDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
    });
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = () => {
        alert("Form has been submitted successfully")
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])

    return (
        <>
            {/* <div className="banner banner2">
                <div className="container">
                    <div className="banner_text">
                        <h3 className="changeCase">Contact Us</h3>
                    </div>
                </div>
            </div> */}

            <div className="container pt-4 pb-4">
                <h1 className="text-center">Contact Us</h1>
                <p className="text-center">Any question or remarks? Just write us a message!</p>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <div className="section contactUsDetails">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <h1>Contact Information</h1>
                                    <p>Let's get this conversation started, Tell us bit about yourself and we'll get in touch as soon as we can. </p>
                                    <ul>
                                        <li><span className="material-symbols-outlined"> mail </span><p> <a href="mailto:customercare.nhl@gmail.com"> customercare.nhl@gmail.com </a>  </p></li>
                                        <li><span className="material-symbols-outlined"> call </span>  <p>+91 [033] 2265 8232 / 2264 7785</p></li>
                                        <li><span className="material-symbols-outlined"> pin_drop </span>  <p><strong>Head Office</strong> 110, A.J.C. Bose Road,<br></br> Kolkata - 700 014</p></li>
                                        <li><span className="material-symbols-outlined"> pin_drop </span>  <p><strong>Production Unit</strong> 47 / C, Pottery Road,<br></br>  Kolkata - 700 015</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="">
                            {/* <div className="contact-form">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <Input
                                                inputName="full_name"
                                                inputType="text"
                                                labelName={"Full Name"}
                                                placeholder="Enter Full Name"
                                                required={true}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="6"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.full_name}
                                            />

                                            <Input
                                                inputName="business_name"
                                                inputType="text"
                                                labelName={"Business Name"}
                                                placeholder="Enter Business Name"
                                                required={false}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="6"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.business_name}
                                            />
                                            <Input
                                                inputName="email_id"
                                                inputType="email"
                                                labelName={"Email ID"}
                                                placeholder="Enter Email ID"
                                                required={true}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="6"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.email_id}
                                            />

                                            <Input
                                                inputName="phone_number"
                                                inputType="text"
                                                labelName={"Phone Number"}
                                                placeholder="Enter Phone Number"
                                                required={false}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="6"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.phone_number}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            inputName="commnet"
                                            inputType="textarea"
                                            labelName={"Comment"}
                                            placeholder="Enter Comment"
                                            required={false}
                                            onChangeSingleCallback={onChangeSingleCallback}
                                            col="12"
                                            isFormSubmitted={isFormSubmitted}
                                            value={formData?.commnet}
                                        />
                                    </div>







                                    <div className="mb-3 col-md-12">
                                        <Button

                                            onClick={() => Submit()}
                                            buttonText={"Submit"}
                                            buttonStyleType={"primary"}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </div> */}

                            <ContactForm col='2'></ContactForm>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contactMap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58954.49753569872!2d88.3365838977023!3d22.554551920560893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0276fa40000001%3A0xc06bf69a93d2b391!2sNational%20Homeo%20Laboratory!5e0!3m2!1sen!2sin!4v1709269939836!5m2!1sen!2sin" width="100%" height="450" loading="lazy" title="contactAddress"></iframe>
            </div>
        </>
    );
};
export default ContactUs;
